import React, { Component, Fragment } from "react"
import styled from "styled-components"

import SEO from "../components/seo"

class NotFoundPage extends Component {
    render() {
        return (
            <Fragment>
                <SEO title="404: Not found" />
                <Header>NOT FOUND</Header>
                <Text>
                    You just hit a route that doesn&#39;t exist... the sadness.
                </Text>
            </Fragment>
        )
    }
}

const Header = styled.h1``
const Text = styled.p``

export default NotFoundPage
